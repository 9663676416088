import * as React from 'react'
import styled from '@emotion/styled'


const StyledHero = styled.div`
width:100%;
height:100vh;
display:flex;
justify-content:center;
align-items:center;

`
const Container = styled.div`
width:100%;
height:100vh;


`

const SixOne = styled.div`
font-family:ThirstyScript Black;
font-size:4.2rem;
line-height:1;
height:50vh;
text-align:center;
padding: 0rem 0;
display:flex;
align-items:flex-end;
justify-content:center;



`

const BisKotti = styled.div`
font-family:'AntiqueOlive Regular';
font-size:2rem;
text-align:center;

`

const LightDark = styled.div`
height:50vh;
width:100%;
background:white;


`

const DarkLight = styled.div`
height:50vh;
width:100%;






}
`





class Hero61 extends React.Component {
  constructor(props: any) {
    super(props)
    this.state = {

    }
  }



  componentDidMount() {

  }

  render() {
    return(


        <StyledHero id={'61bc'}>
         <Container>
           <LightDark>
          <SixOne>61bc</SixOne>
           </LightDark>
           <DarkLight>
        <BisKotti>entertainment</BisKotti>
           </DarkLight>
         </Container>
        </StyledHero>



    )
  }
}


export default Hero61


//<P><Zoom delay={4200} right cascade>CBD BLÜTEN</Zoom></P>
